import React from "react"
import Modal from "react-bootstrap/Modal"

const Success = props => {
  return (
    <>
      <Modal {...props}>
        <Modal.Header
          closeButton
          className="text-right border-0 py-0"
        ></Modal.Header>
        <Modal.Body>
          <p>
            Thank you for your interest in BusinessIQ Investment Club where we
            make you richer through passive income in secured investments. You
            will get an email shortly. Kindly check your email.
          </p>
          <p>
            If you do not recieve an email, kindly email:{" "}
            <a href="mailto:investmentclub@businessiqafrica.com">
              investmentclub@businessiqafrica.com
            </a>
          </p>
        </Modal.Body>
      </Modal>
    </>
  )
}

export default Success
