import React from "react"

const index = ({ touched, message }) => {
  if (!touched) {
    return (
      <>
        <div>
          <p
            className="small text-danger mb-0 mt-2"
            style={{ fontSize: "80%" }}
          ></p>
        </div>
      </>
    )
  }
  if (message) {
    return (
      <>
        <p className="small text-danger mb-0 mt-2" style={{ fontSize: "80%" }}>
          {message}
        </p>
      </>
    )
  }
  return (
    <>
      <p
        className="small text-success mb-0 mt-2"
        style={{ fontSize: "80%" }}
      ></p>
    </>
  )
}

export default index
