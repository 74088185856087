import React, { useState } from "react"
import { useParams } from "@reach/router"
import { useFormik } from "formik"
import axios from "axios"
import * as Yup from "yup"
import { useToasts } from "react-toast-notifications"
import FormError from "../formError"
import Success from "../successModal"

import "./index.css"

import regThumb from "../../images/reg-thumb.png"

const validationSchema = Yup.object().shape({
  firstname: Yup.string()
    .min(4, "Must enter a valid name")
    .required("Must enter your first name"),
  lastname: Yup.string()
    .min(4, "Must enter a valid name")
    .required("Must enter your last name"),
  email: Yup.string()
    .email("Must be a valid email address")
    .required("Must enter an email"),
  phone: Yup.string().matches(/^[0-9]{11}$/, "Must be exactly 11 digits"),
  package: Yup.string().required("Must enter a linkedin profile link"),
})

var Airtable = require("airtable")

Airtable.configure({
  endpointUrl: "https://api.airtable.com",
  apiKey: process.env.GATSBY_AIRTABLE_KEY,
})

var base = Airtable.base(process.env.GATSBY_AIRTABLE_BASE)

var table = base("Partners")

const Index = props => {
  const { addToast } = useToasts()
  const createRecord = async fields => {
    const createdRecord = await table.create(fields)
    axios
      .post(
        "https://investmentclub.businessiqafrica.com/.netlify/functions/send-email",
        {
          firstname: fields.Firstname,
          email: fields.Email,
          package: fields.Package,
        }
      )
      .then(function (response) {
        console.log(response)
        addToast("Application Successful", {
          appearance: "success",
          placement: "top-center",
          autoDismiss: true,
        })
      })
      .catch(function (error) {
        console.log(error)
        // addToast("Application Successful", {
        //   appearance: "success",
        //   placement: "top-center",
        //   autoDismiss: true,
        // })
      })
  }

  const params = useParams()

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      firstname: "",
      lastname: "",
      email: "",
      phone: "",
      package: params.plan ? params.plan : "",
      referral: "",
    },
    validationSchema,
    onSubmit: async values => {
      formik.setSubmitting(true)
      // save data on airtable
      await createRecord({
        Firstname: values.firstname,
        Lastname: values.lastname,
        Email: values.email,
        Phone: values.phone,
        Package: values.package,
      })

      formik.resetForm()
      setModalShow("success")
    },
  })

  const [modalShow, setModalShow] = useState(false)

  return (
    <>
      <div className="right">
        <div className="container">
          <div className="row align-center">
            <div className="col-lg-6">
              <img src={regThumb} alt="" className="img-fluid  my-4" />
            </div>
            <div className="col-lg-6">
              <form
                className="registration__form mt-lg-5 my-3 px-lg-5"
                onSubmit={formik.handleSubmit}
              >
                <div className="form-row justify-content-centr">
                  <div className="form-group col-lg-12">
                    <label htmlFor="name">First Name</label>
                    <input
                      type="text"
                      className="form-control"
                      autoComplete="given-name"
                      id="firstname"
                      name="firstname"
                      placeholder="First Name"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.firstname}
                    />
                    <FormError
                      touched={formik.touched.firstname}
                      message={formik.errors.firstname}
                    />
                  </div>

                  <div className="form-group col-lg-12">
                    <label htmlFor="lastname">Last Name</label>
                    <input
                      type="text"
                      autoComplete="family-name"
                      className="form-control"
                      id="lastname"
                      name="lastname"
                      placeholder="Last Name"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.lastname}
                    />

                    <FormError
                      touched={formik.touched.lastname}
                      message={formik.errors.lastname}
                    />
                  </div>
                </div>

                <div className="form-row">
                  <div className="form-group col-lg-12">
                    <label htmlFor="email">Email Address</label>
                    <input
                      type="email"
                      autoComplete="email"
                      className="form-control"
                      id="email"
                      name="email"
                      placeholder="Email"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.email}
                    />

                    <FormError
                      touched={formik.touched.email}
                      message={formik.errors.email}
                    />
                  </div>
                </div>

                <div className="form-row">
                  <div className="form-group col-lg-12">
                    <label htmlFor="phone">Phone Number</label>
                    <input
                      type="tel"
                      autoComplete="tel"
                      className="form-control"
                      id="phone"
                      name="phone"
                      placeholder="Phone"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.phone}
                    />

                    <FormError
                      touched={formik.touched.phone}
                      message={formik.errors.phone}
                    />
                  </div>
                </div>

                <div className="form-row">
                  <div className="form-group col-lg-12">
                    <label htmlFor="phone">Investment Package</label>
                    <select
                      className="form-control custom-select"
                      id="package"
                      name="package"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.package}
                    >
                      <option value="">Choose an option below</option>
                      <option value="smart_investor">Smart Investor</option>
                      <option value="premium_investor">Premium Investor</option>
                      <option value="prestigious_partner">
                        Prestigious Partner
                      </option>
                      <option value="lifetime_partner">Lifetime Partner</option>
                    </select>

                    <FormError
                      touched={formik.touched.package}
                      message={formik.errors.package}
                    />
                  </div>
                </div>

                <div className="form-row">
                  <div className="form-group col-lg-12">
                    <div className="">
                      <button
                        type="submit"
                        className="btn btn--primary px-5 mt-4 btn-block"
                      >
                        {formik.isSubmitting ? "Submiting..." : "Register"}
                      </button>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>

      {modalShow === "success" && (
        <Success
          show={modalShow === "success"}
          onHide={() => setModalShow(false)}
        />
      )}
    </>
  )
}

export default Index
