import React from "react"
import { Router } from "@reach/router"
import Layout from "../components/Layout"

import StartRegisteration from "../components/Registration/index.js"

const Register = () => {
  return (
    <>
      <Layout>
        <Router>
          <StartRegisteration exact path="/register" />

          <StartRegisteration exact path="/register/:plan" />
        </Router>
      </Layout>
    </>
  )
}

export default Register
